<template>
  <div>
    <div class="pa-4">
      <v-select
          v-model="filter.status"
          :items="states"
          item-text="name"
          item-value="value"
          chips
          small-chips
          dense
          filled
          label="Statut"
          hide-details
          rounded
          class="rounded mb-2">
        <template v-slot:selection="data">
          <v-chip small :color="data.item.color" dark>
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar size="10">
            <v-icon :color="data.item.color">mdi-circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-select>
      <v-select v-model="filter.raison_id"
                :items="raisons"
                item-text="name"
                item-value="id"
                chips
                small-chips
                clearable
                dense
                filled
                label="Raison"
                hide-details
                rounded
                class="rounded mb-2"/>
      <v-select v-model="filter.product_ids"
                :items="products"
                item-text="name"
                item-value="id"
                chips
                small-chips
                multiple
                deletable-chips
                dense
                filled
                label="Produits manquant"
                hide-details
                rounded
                class="rounded mb-2"/>
      <v-select v-model="filter.user_ids"
                :items="users"
                :item-text="(item) => {return item.first_name+ ' ' + item.last_name}"
                item-value="id"
                chips
                small-chips
                multiple
                deletable-chips
                dense
                filled
                label="Utilisateur"
                hide-details
                rounded
                class="rounded mb-2"/>
      <v-btn block color="primary" depressed class="rounded-lg" large @click="onFilter">
        Filtrer
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        status: 'all',
      },
      states: [
        {name: 'Validée temp', value: 'temp_validated', color: 'success'},
        {name: 'Refusée temp', value: 'temp_refused', color: 'red'},
        {name: 'Tous', value: 'all', color: 'info'}
      ],
    }
  },
  methods: {
    onFilter() {
      this.$emit('filter', this.filter)
    },
  },
  computed: {
    raisons() {
      return this.$store.state.raisons
    },
    users() {
      return this.$store.state.users
    },
    products() {
      return this.$store.state.products
    },
  }
}
</script>

<style scoped>

</style>