<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center" :loading="loading" :disabled="loading">
        <v-card-text class="pa-6">
          <v-icon color="primary" size="80">mdi-information-outline</v-icon>
          <h2 class="text--primary mt-3">Confirmation</h2>

          <p class="mt-5 mb-2">
            Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Vestibulum maximus vel ex
            vitae pretium.
          </p>

          <h4 class="mb-3">
            Total réponses sélectionnées :
            <v-chip color="blue" dark small> {{ selectedTasks.length }}</v-chip>
          </h4>

          <h4 class="mb-3 success--text">
            <v-icon left color="success" class="mt--4">mdi-check-circle-outline</v-icon>
            Total tâches validées : {{ validated_selected_total() }}
          </h4>

          <h4 class="error--text">
            <v-icon left color="error" class="mt--4">mdi-close-circle-outline</v-icon>
            Total tâches réfuées : {{ refused_selected_total() }}
          </h4>

          <v-row justify="center" align="center" class="my-3">
            <v-col cols="8">
              <v-select :items="states" item-text="name" item-value="value" v-model="changed_status"
                        hide-details
                        chips filled dense label="Sélectionnez un état">
                <template v-slot:selection="data">
                  <v-chip small :color="data.item.color" dark>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar size="10">
                    <v-icon :color="data.item.color">mdi-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-btn text class="mr-2"
                 @click="close">
            <v-icon left>mdi-close</v-icon>
            Annuler
          </v-btn>

          <v-btn color="primary" depressed
                 :disabled="!changed_status"
                 :loading="loading"
                 @click="chnageStatus">
            <v-icon left>mdi-check-circle-outline</v-icon>
            Ok
          </v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['dialog', 'selectedTasks', 'tasks'],
  data() {
    return {
      loading: false,
      states: [
        {
          name: 'Accepter',
          value: 'temp_validated',
          color: 'success',
        },
        {
          name: 'Réfuser',
          value: 'temp_refused',
          color: 'error',
        }
      ],
      changed_status: ''
    }
  },
  methods: {
    chnageStatus() {
      this.loading = true
      this.$Progress.start()
      HTTP.post('/tasks/final/change-all-status', {
        task_salepoint_ids: this.selectedTasks,
        changed_status: this.changed_status,
        task_id: parseInt(this.$route.params.id),
      }).then(() => {
        this.$successMessage = 'Ces tâches ont été modifiées avec succès'
        this.loading = false
        this.$Progress.finish()
        this.close()
        this.$emit('get-tasks')
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    close() {
      this.$emit('update:dialog', false)
    },
    validated_selected_total() {
      const tasks = this.tasks.filter(({id}) => this.selectedTasks.includes(id));
      return tasks.filter(el => el.status === 'temp_validated').length
    },
    refused_selected_total() {
      const tasks = this.tasks.filter(({id}) => this.selectedTasks.includes(id));
      return tasks.filter(el => el.status === 'temp_refused').length
    },
  }
}
</script>

<style scoped>

</style>