<template>
  <div>
    <v-dialog v-model="dialog" width="400" persistent scrollable>
      <v-card>
        <v-card-title>
          Remarque
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">
          {{ remark }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: ['dialog', 'remark'],
  methods: {
    close() {
      this.$emit('update:dialog', false)
    }
  },
}
</script>

<style scoped>

</style>