<template>
  <div>
    <v-container fluid>
      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
                color="primary"
                indeterminate
                size="50"
        ></v-progress-circular>
      </v-overlay>
      <v-row>

        <v-col cols="7">
          <Toolbar/>
        </v-col>

        <v-col class="text-right" cols="5">

          <v-btn :class="selectedTasks.length===0 ?'' :'white--text'"
                 :disabled="selectedTasks.length===0"
                 class="mt-7 mr-2"
                 color="orange"
                 depressed
                 @click="chnageStatusDialog = true">
            <v-icon left>mdi-pencil-outline</v-icon>
            Changer l'état
          </v-btn>

          <v-btn :disabled="loading"
                 class="mt-7"
                 color="success"
                 depressed
                 @click="validateDialog=true">
            <v-icon left>mdi-check-circle-outline</v-icon>
            Valider
          </v-btn>

        </v-col>

        <v-col cols="12">
          <StatisticCard :loading="loading"
                         :statistic.sync="statistic"/>
        </v-col>

        <v-col cols="12">
          <v-card class="rounded-lg shadow">
            <v-card-title>
              <div class="w-300">
                <v-text-field v-model="keyword"
                              append-icon="mdi-magnify"
                              clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>
              <v-spacer/>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="refresh"
                         v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="drawer = true"
                         v-on="on">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <span>Filtrer</span>
              </v-tooltip>

            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-card-text v-if="!loading && tasks.length === 0" class="text-center">
              <NoResult/>
            </v-card-text>
            <div v-if="!loading && tasks.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      <v-icon :color="selectedTasks.length > 0 ? 'blue' : ''"
                              @click="toggle" @mousedown.prevent>
                        {{ icon }}
                      </v-icon>
                    </th>
                    <th>POS</th>
                    <th>Utilisateur</th>
                    <th>Raison</th>
                    <th>Remarque</th>
                    <th>Statut</th>
                    <th class="text-right">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in tasks" :key="item.id"
                      :class="selectedTasks.includes(item.id) ? 'v-data-table__selected' : ''">

                    <td>
                      <v-checkbox v-model="selectedTasks"
                                  :value="item.id"
                                  class="pt-0 mt-0"
                                  color="blue"
                                  hide-details
                                  multiple></v-checkbox>
                    </td>

                    <td>
                      <span class="text--primary cursor-pointer"
                            @click="[salepoint = item.salepoint,salepointDialog=true]">{{ item.salepoint.name }}<span
                              class="font-weight-bold blue--text">+</span>
                      </span>
                    </td>

                    <td class="text-center">
                      {{ item.user ? item.user.first_name + ' ' + item.user.last_name : '-' }}
                    </td>

                    <td class="text-center">{{ item.raison ? item.raison.name : '-' }}</td>

                    <td>
                      <div v-if="item.remark">
                        {{ item.remark.substr(0, 20) }}
                        {{ item.remark.length > 20 ? '...' : '' }}

                        <span v-if="item.remark.length > 20"
                              class="text-primary cursor-pointer"
                              @click="[remarkDialog = true,remark=item.remark]">
                          Plus
                        </span>

                      </div>
                      <div v-else>-</div>
                    </td>

                    <td class="text-center">
                      <v-chip :color="item.state.color" dark small>
                        <v-icon v-if="item.state.origin === 'temp_validated' || item.state.origin  === 'temp_refused'" left
                                small>
                          mdi-clock-check-outline
                        </v-icon>
                        {{ item.state.value }}
                      </v-chip>
                    </td>

                    <td class="text-right">
                      <v-tooltip color="primary" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mr-1" color="primary" icon v-bind="attrs"
                                 @click="showResponses(item)"
                                 v-on="on">
                            <v-icon>mdi-script-text-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Voir les réponses</span>
                      </v-tooltip>
                      <v-tooltip v-if="item.products.length" color="primary" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" icon v-bind="attrs" @click="[missingProductsDialog=true,missing_products=item.products]"
                                 v-on="on">
                            <v-icon>mdi-cart-minus</v-icon>
                          </v-btn>
                        </template>
                        <span>Voir les produits manquant</span>
                      </v-tooltip>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                    <div>
                        <v-select v-model="per_page"
                                  :items="[10,20,50,100,200,300,400,500]"
                                  :style="{width: '120px'}"
                                  dense
                                  hide-details
                                  label="Ligne par page"
                                  outlined></v-select>
                    </div>

                    <v-spacer/>
                    <v-pagination v-if="total > 10"
                                  v-model="pagination.current"
                                  :length="pagination.total"
                                  circle
                                  total-visible="6"
                                  @input="onPageChange"
                    ></v-pagination>
                </v-card-actions>

            </div>
          </v-card>
        </v-col>
      </v-row>

      <!--Dialogs && Drawer-->
      <v-navigation-drawer
              v-model="drawer"
              :permanent="drawer" app
              clipped
              floating
              right
              temporary
              width="400">
        <v-list-item>
          <v-list-item-avatar color="blue">
            <v-icon dark>mdi-tune</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Filtrages</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
        <FilterDrawer ref="FilterDrawer" @filter="getTasks"/>
      </v-navigation-drawer>

      <ResponsesDialog ref="resDialog"
                       :dialog.sync="responsesDialog"
                       :task.sync="task"
                       @get-tasks="getTasks"/>

      <SalepointDialog v-if="salepoint"
                       :dialog.sync="salepointDialog"
                       :salepoint.sync="salepoint"/>

      <ValidateDialog :dialog.sync="validateDialog"
                      :statistic="statistic"/>

      <ChangeStatusDialog :dialog.sync="chnageStatusDialog"
                          :selected-tasks.sync="selectedTasks"
                          :tasks.sync="tasks"
                          @get-tasks="[getTasks(),selectedTasks=[]]"/>

      <MissingProductsDialog :dialog.sync="missingProductsDialog"
                             :products.sync="missing_products"/>

      <RemarkDialog :dialog.sync="remarkDialog"
                    :remark="remark"/>

      <!--Dialogs && Drawer-->

    </v-container>
  </div>
</template>

<script>
import Toolbar from "../Toolbar";
import FilterDrawer from "./FilterDrawer";
import ResponsesDialog from "../Dialogs/ResponsesDialog";
import SalepointDialog from "../Dialogs/SalepointDialog";
import {HTTP} from "@/http-common";
import ValidateDialog from "./Dialogs/ValidateDialog";
import ChangeStatusDialog from "./Dialogs/ChangeStatusDialog";
import StatisticCard from "./StatisticCard";
import NoResult from "../../../components/NoResult";
import MissingProductsDialog from "../Dialogs/MissingProductsDialog";
import RemarkDialog from "../Dialogs/RemarkDialog";

export default {
    components: {
        RemarkDialog,
        MissingProductsDialog,
        NoResult,
        StatisticCard,
        ChangeStatusDialog, ValidateDialog, Toolbar, FilterDrawer, SalepointDialog, ResponsesDialog
    },
    data() {
        return {
            drawer: false,
            loading: false,
            overlay: false,
            tasks: [],

            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },

            statistic: {
                task: '',
                responses_total: '',
                validated_total: '',
                refused_total: '',
                validated_percent: '',
                refused_percent: '',
                raisons: [],
            },

            task_id: null,
            salepoint_id: null,
            responsesDialog: false,
            task: '',
            salepoint: '',
            salepointDialog: false,

            selectedTasks: [],
            validateDialog: false,
            chnageStatusDialog: false,

            missingProductsDialog: false,
            missing_products: [],

            keyword: '',
            filter: {
                status: 'all'
            },

            remarkDialog: false,
            remark: ''
        }
    },
    methods: {
        getTasks(filter) {
            this.filter = filter ? filter : this.filter
            this.loading = true
            this.drawer = false
            this.$Progress.start()
            HTTP.get('/tasks/final/' + parseInt(this.$route.params.id) + '?page=' + this.pagination.current, {
                params: {
                    per_page: this.per_page,
                    keyword: this.keyword,
                    filter: this.filter,
                }
            }).then((res) => {
                this.loading = false
                this.$Progress.finish()
                this.tasks = res.data.data.data
                this.pagination.current = res.data.data.current_page;
                this.pagination.total = res.data.data.last_page;
                this.total = res.data.data.total;
                this.statistic = res.data.statistic;

            }).catch(err => {
                this.loading = false
                this.$Progress.fail()
                console.log(err)
            })
        },
        onPageChange() {
            this.getTasks()
        },
        refresh() {
            this.filter = {
                status: 'all'
            }
            this.getTasks()
        },
        showResponses(task) {
            this.task = task
            this.responsesDialog = true
            this.$refs.resDialog.showResponses(task.salepoint_id, task.task_id)
        },
        toggle() {
            if (this.selectedTasks.length === this.tasks.length) {
                this.selectedTasks = []
            } else {
                this.selectedTasks = this.tasks.map(el => el.id)
            }
        },
    },
    computed: {
        icon() {
            if (this.selectedTasks.length === this.tasks.length) return 'mdi-close-box'
            if (this.selectedTasks.length > 0 && this.selectedTasks.length !== this.tasks.length) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    created() {
        this.getTasks()
    },
    watch: {
        '$is_confirm': function (el) {
            if (el) {
                this.$is_confirm = false
                this.overlay = true
                this.changeStatus()
            }
        },
        keyword() {
            this.getTasks();
        },
        per_page() {
            this.pagination.current = 1
            this.getTasks();
        }
    },
}
</script>

<style scoped>

</style>