<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent scrollable>
      <v-card>
        <v-card-title>
          Produits manquant
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>
        <v-list v-for="(p,i) in products" :key="i">
          <v-list-item>
            <v-list-item-avatar rounded>
              <v-img v-if="p.photo" :src="FILE_URL + p.photo"/>
              <v-img v-else :src="require('@/assets/no-image.png')"/>
            </v-list-item-avatar>
            <v-list-item-title>
              {{p.name}}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: ['dialog', 'products'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
    }
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    }
  },
}
</script>

<style scoped>

</style>