<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center" :loading="loading" :disabled="loading">
        <v-card-text class="pa-6">
          <v-icon color="primary" size="80">mdi-information-outline</v-icon>
          <h2 class="text--primary mt-3">Confirmation</h2>

          <p class="mt-5 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Vestibulum maximus vel ex
            vitae pretium.
          </p>

          <h4 class="mb-3">Tâche :
            <span class="text--primary">{{ statistic.task.name }}</span>
          </h4>

          <h4 class="mb-3">Points gagnants:
            <v-chip color="blue" dark small>
              {{ (statistic.task.winning_points * statistic.validated_total) | toCurrency }}
            </v-chip>
          </h4>

          <h4 class="mb-3">
            Total réponses :
            <v-chip color="success" dark small>{{ statistic.responses_total }}</v-chip>
          </h4>

          <h4 class="mb-3 success--text">
            <v-icon left color="success" class="mt--4">mdi-check-circle-outline</v-icon>
            {{ statistic.validated_total }}-Tâches validées
            <v-progress-circular :rotate="360"
                                 :size="50"
                                 :width="3"
                                 :value="statistic.validated_percent"
                                 :color="setColor(statistic.validated_percent)">
              <small>{{ statistic.validated_percent }}%</small>
            </v-progress-circular>
          </h4>

          <h4 class="mb-8 error--text">
            <v-icon left color="error" class="mt--4">mdi-close-circle-outline</v-icon>
            {{ statistic.refused_total }}-Tâches réfuées
            <v-progress-circular :rotate="360"
                                 :size="50"
                                 :width="3"
                                 :value="statistic.refused_percent"
                                 :color="setColor(statistic.refused_percent)">
              <small>{{ statistic.refused_percent }}%</small>
            </v-progress-circular>
          </h4>

          <v-btn text class="mr-2" @click="close">
            <v-icon left>mdi-close</v-icon>
            Annuler
          </v-btn>
          <v-btn color="primary" depressed
                 :loading="loading"
                 @click="validateAllTask">
            <v-icon left>mdi-check-circle-outline</v-icon>
            Valider
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['dialog', 'statistic'],
  data() {
    return {
      loading: false
    }
  },
  methods: {
    validateAllTask() {
      this.$Progress.start()
      this.loading = true
      HTTP.post('/tasks/final/validate-all', {
        task_id: parseInt(this.$route.params.id)
      }).then(() => {
        this.loading = false
        this.$Progress.finish()
        this.$successMessage = 'Ces tâches ont été validées avec succès'
        this.$router.push('/backoffice/completed')
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    close() {
      this.$emit('update:dialog', false)
    },
    setColor(el) {
      if (el <= 30) {
        return 'red'
      }
      if (el > 30 && el <= 60) {
        return 'yellow'
      }
      if (el > 60 && el <= 90) {
        return 'primary'
      }
      if (el > 90 && el <= 100) {
        return 'success'
      }
    }
  }
}
</script>

<style scoped>

</style>